import { loginApi, refreshTokenApi, logoutApi } from '../api/auth_api';
import { SUPERADMIN_TOKEN_KEY, LOG } from '../config/constants';

export const ADMIN_LOGIN = 'ADMIN_LOGIN';
export const ADMIN_REFRESH_TOKEN = 'ADMIN_REFRESH_TOKEN';
export const ADMIN_REFRESH_TOKEN_FAILED = 'ADMIN_REFRESH_TOKEN_FAILED';
export const ADMIN_LOGGING_IN = 'ADMIN_LOGGING_IN';
export const ADMIN_LOGIN_ERROR = 'ADMIN_LOGIN_ERROR';
export const ADMIN_LOGOUT = 'ADMIN_LOGOUT';

export const loginUser = async (dispatch, payload) => {
  dispatch({ type: ADMIN_LOGGING_IN });
  let res;
  try {
    res = await loginApi(payload.username, payload.password);
  } catch(e) {
    if (LOG) {
      console.log(e.response);
    }
    dispatch({ type: ADMIN_LOGIN_ERROR });
  }
  if (res) {
    const { token, email, name } = res.data.data;
    localStorage.setItem(SUPERADMIN_TOKEN_KEY, token);
    dispatch({ type: ADMIN_LOGIN, payload: { name, username: email, token }});
  }
};

export const refreshToken = async (dispatch) => {
  dispatch({ type: ADMIN_REFRESH_TOKEN });
  let res;
  try {
    res = await refreshTokenApi();
  } catch(e) {
    dispatch({ type: ADMIN_REFRESH_TOKEN_FAILED });
  }
  if (res) {
    const { token, email, name } = res.data.data;
    localStorage.setItem(SUPERADMIN_TOKEN_KEY, token);
    dispatch({ type: ADMIN_LOGIN, payload: { name, username: email, token }});
  }
};

export const logoutAdmin = async (dispatch) => {
  dispatch({ type: ADMIN_LOGOUT });
  await logoutApi();
  localStorage.setItem(SUPERADMIN_TOKEN_KEY, '');
};

export default function reducer(state = {
  loggedIn: false,
  loggingIn: false,
  loginError: false,
  refreshingToken: false,
  name: '',
  username: '',
  token: '',
}, action) {
  switch (action.type) {
    case ADMIN_LOGIN:
      return {
        ...state,
        loggedIn: true,
        loggingIn: false,
        loginError: false,
        refreshingToken: false,
        name: action.payload.name,
        token: action.payload.token,
        username: action.payload.username,
      };
    case ADMIN_LOGGING_IN:
      return {
        ...state,
        loggedIn: false,
        loggingIn: true,
        loginError: false,
        refreshingToken: false,
      };
    case ADMIN_LOGIN_ERROR:
      return {
        ...state,
        loggedIn: false,
        loggingIn: false,
        loginError: true,
        refreshingToken: false,
      };
    case ADMIN_REFRESH_TOKEN:
      return {
        ...state,
        loggedIn: false,
        loggingIn: false,
        loginError: false,
        refreshingToken: true,
      };
    case ADMIN_REFRESH_TOKEN_FAILED:
      return {
        ...state,
        loggedIn: false,
        loggingIn: false,
        loginError: false,
        refreshingToken: false,
      };
    case ADMIN_LOGOUT:
      return {
        ...state,
        loggedIn: false,
        loggingIn: false,
        loginError: false,
        refreshingToken: false,
      };
    default:
      return state;
  }
}
