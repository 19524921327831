import React, {Fragment} from 'react';
import { DropdownToggle, UncontrolledButtonDropdown, DropdownMenu } from 'reactstrap';
import { toast, Bounce } from 'react-toastify';
import { connect } from 'react-redux';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import avatar1 from '../../../assets/utils/images/avatars/avatar.png';
import { logoutAdmin } from '../../../reducers/Auth';

class UserBox extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            active: false,
        };

    }

    notify2 = () => this.toastId = toast("You don't have any new items in your calendar for today! Go out and play!", {
        transition: Bounce,
        closeButton: true,
        autoClose: 5000,
        position: 'bottom-center',
        type: 'success'
    });

    logout() {
      this.props.logoutAdmin();
    }


    render() {
      const name = this.props.Auth.name;

        return (
            <Fragment>
                <div className="header-btn-lg pr-0">
                    <div className="widget-content p-0">
                        <div className="widget-content-wrapper">
                            <div className="widget-content-left">
                                <UncontrolledButtonDropdown>
                                    <DropdownToggle color="link" className="p-0">
                                        <img width={42} className="rounded-circle" src={avatar1} alt=""/>
                                        <FontAwesomeIcon className="ml-2 opacity-8" icon={faAngleDown}/>
                                    </DropdownToggle>
                                    <DropdownMenu right className="rm-pointers dropdown-menu-lg">
                                      <div className="nav-item-header m-2" style={{ cursor: 'pointer' }} onClick={() => this.logout()}>
                                        Logout
                                      </div>
                                    </DropdownMenu>
                                </UncontrolledButtonDropdown>
                            </div>
                            <div className="widget-content-left  ml-3 header-user-info">
                                <div className="widget-heading">
                                    {name}
                                </div>
                                <div className="widget-subheading">
                                    username
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}

const mapStateToProp = state => ({
    Auth: state.Auth,
});

const mapDispatchToProps = dispatch => {
  return {
    logoutAdmin: (payload) => logoutAdmin(dispatch),
  }
}

export default connect(mapStateToProp, mapDispatchToProps)(UserBox);
