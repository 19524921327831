import React, {Fragment} from 'react';
import cx from 'classnames';
import {connect} from 'react-redux';
import HeaderLogo from '../AppLogo';
import UserBox from './Components/UserBox';


class Header extends React.Component {
    render() {
        let {
            enableMobileMenuSmall,
        } = this.props;
        return (
            <Fragment>
                <HeaderLogo/>
                <div className={cx(
                    "app-header__content",
                    {'header-mobile-open': enableMobileMenuSmall},
                )}>
                    <div className="app-header-right">
                        <UserBox type={this.props.type} />
                    </div>
                </div>
            </Fragment>
        );
    }
}

const mapStateToProps = state => ({
    enableHeaderShadow: state.ThemeOptions.enableHeaderShadow,
    closedSmallerSidebar: state.ThemeOptions.closedSmallerSidebar,
    headerBackgroundColor: state.ThemeOptions.headerBackgroundColor,
    enableMobileMenuSmall: state.ThemeOptions.enableMobileMenuSmall,
});

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
