import {BrowserRouter as Router, Route, Redirect} from 'react-router-dom';
import React, {Suspense, lazy, Fragment, Component} from 'react';
import { Spinner } from 'reactstrap';
import { connect } from 'react-redux';
import Login from '../../Containers/Login';
import { refreshToken } from '../../reducers/Auth';


import AppHeader from '../../Layout/AppHeader';
import AppSidebar from '../../Layout/AppSidebar';
const Tenants = lazy(() => import('../../Containers/Tenants'));
const Feedback = lazy(() => import('../../Containers/Feedback'));
const Classes = lazy(() => import('../../Containers/Classes'));
const Language = lazy(() => import('../../Containers/Language'));
const Subject = lazy(() => import('../../Containers/Subject'));
const Chapter = lazy(() => import('../../Containers/Chapter'));
const Videos = lazy(() => import('../../Containers/Videos'));
const Settings = lazy(() => import('../../Containers/Settings'));
const Guide = lazy(() => import('../../Containers/Guide'));

class AppMain extends Component {
  componentDidMount() {
    this.props.refreshToken();
  }

  render() {
    if (!this.props.Auth.loggedIn) return (
      <Login />
    );

    return (
      <Fragment>
        <div className='app-header bg-strong-bliss header-text-light header-shadow'>
          <AppHeader type="Admin" />
        </div>
        <div className="app-main">
          <AppSidebar menuType={'admin'} />
          <div className="app-main__outer">
            {/* Tenants */}

            <Suspense fallback={
              <div className="loader-container">
                <Spinner size="lg" color="primary" />
              </div>
            }>
              <Route path="/tenants" component={Tenants}/>
            </Suspense>
            {/* Feedback */}

            <Suspense fallback={
              <div className="loader-container">
                <Spinner size="lg" color="primary" />
              </div>
            }>
              <Route path="/feedback" component={Feedback}/>
            </Suspense>

          {/* Classes */}

            <Suspense fallback={
              <div className="loader-container">
                <Spinner size="lg" color="primary" />
              </div>
            }>
              <Route path="/classes" component={Classes}/>
            </Suspense>



          {/* Languages */}

            <Suspense fallback={
              <div className="loader-container">
                <Spinner size="lg" color="primary" />
              </div>
            }>
              <Route path="/language" component={Language}/>
            </Suspense>




            {/* Subject */}

            <Suspense fallback={
              <div className="loader-container">
                <Spinner size="lg" color="primary" />
              </div>
            }>
              <Route path="/subject" component={Subject}/>
            </Suspense>


          {/* Chapter */}

            <Suspense fallback={
              <div className="loader-container">
                <Spinner size="lg" color="primary" />
              </div>
            }>
              <Route path="/chapters" component={Chapter}/>
            </Suspense>

          {/* Videos */}

            <Suspense fallback={
              <div className="loader-container">
                <Spinner size="lg" color="primary" />
              </div>
            }>
              <Route path="/videos/:id/:name" component={Videos}/>
            </Suspense>


          {/* Profile */}

            <Suspense fallback={
              <div className="loader-container">
                <Spinner size="lg" color="primary" />
              </div>
            }>
              <Route path="/profile" component={Settings}/>
            </Suspense>

            {/* Guides */}

            <Suspense fallback={
              <div className="loader-container">
                <Spinner size="lg" color="primary" />
              </div>
            }>
              <Route path="/guide" component={Guide} />
            </Suspense>

            <Route exact path="/" render={() => (
              <Redirect to="/tenants"/>
            )}/>
          </div>
        </div>
      </Fragment>
    )
  };
}

const mapStateToProp = state => ({
    Auth: state.Auth,
});

const mapDispatchToProps = dispatch => {
  return {
    refreshToken: () => refreshToken(dispatch),
  }
}

export default connect(mapStateToProp, mapDispatchToProps)(AppMain);
