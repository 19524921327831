import React, { Component, Fragment } from 'react';
import { Button, Card, CardTitle, CardBody, Form, FormGroup, FormFeedback, Input, Label, Spinner } from 'reactstrap';
import { connect } from 'react-redux';
import { loginUser } from '../../reducers/Auth';

class Login extends Component {
  state = {
    username: '',
    password: '',
  }

  handleEnterPress(e) {
    if(e.charCode === 13) {
      this.props.loginUser({ username: this.state.username, password: this.state.password });
    }
  }

  render() {
    return(
      <Fragment>
          <div className="loader-container">
            {
              (this.props.Auth.refreshingToken) ?
              <Spinner size="lg" color="primary" /> :
              <Card className="col-xs-10 col-sm-8 col-md-6 col-lg-4">
                <CardBody>
                  <CardTitle>SuperAdmin Login</CardTitle>
                  <Form className="mt-4">
                    <FormGroup>
                      <Label for="exampleEmail">Username</Label>
                      <Input invalid={this.props.Auth.loginError} value={this.state.username} onChange={(e) => this.setState({ username: e.target.value })} onKeyPress={(e) => this.handleEnterPress(e)} />
                    </FormGroup>
                    <FormGroup>
                      <Label for="exampleEmail">Password</Label>
                      <Input invalid={this.props.Auth.loginError} type="password" value={this.state.password} onChange={(e) => this.setState({ password: e.target.value })} onKeyPress={(e) => this.handleEnterPress(e)}/>
                      <FormFeedback invalid>Unable to login</FormFeedback>
                    </FormGroup>
                  </Form>
                  {
                    (this.props.Auth.loggingIn) ?
                    <Spinner size="lg" color="primary" /> :
                    <Button className="col-3 mt-2" onClick={() => this.props.loginUser({ username: this.state.username, password: this.state.password })} color="primary">Login</Button>
                  }
                </CardBody>
              </Card>
            }
          </div>
      </Fragment>
    );
  }
}

const mapStateToProp = state => ({
    Auth: state.Auth,
});

const mapDispatchToProps = dispatch => {
  return {
    loginUser: (payload) => loginUser(dispatch, payload),
  }
}

export default connect(mapStateToProp, mapDispatchToProps)(Login);
