const DEV_ENV = false;
const PROD_URL = 'https://api.indversity.com';
const DEV_URL = 'http://sagokomm-mini';

export const BASE_URL = DEV_ENV ? DEV_URL : PROD_URL;
export const SUPERADMIN_TOKEN_KEY = 'SUPERADMIN_TOKEN_KEY';
export const LOG = false;
export const DEFAULT_EMPLOYEE_PASSWORD = 'password';
export const OTP_DISABLE_THRESHOLD = 0;
export const DEFAULT_SETTINGS = {
  default_password: 'password',
  organization_name: 'indversity',
  sms_credits: 1000,
  otp_reset_enable: true,
};

export const mainDomain = 'indversity';

export const dropdownHeightModifier = {
  setMaxHeight: {
    enabled: true,
    fn: (data) => {
      return {
        ...data,
        styles: {
          ...data.styles,
          overflow: 'auto',
          maxHeight: '240px',
        },
      };
    },
  },
};

export const STAFF_TYPES = [ 'teaching', 'non_teaching', 'support' ];

export const fakeData = [
    {name: 'Page A', uv: 4000, pv: 2400, amt: 2400},
    {name: 'Page B', uv: 3000, pv: 1398, amt: 2210},
    {name: 'Page C', uv: 2000, pv: 9800, amt: 2290},
    {name: 'Page D', uv: 2780, pv: 3908, amt: 2000},
    {name: 'Page E', uv: 1890, pv: 4800, amt: 2181},
    {name: 'Page F', uv: 2390, pv: 3800, amt: 2500},
    {name: 'Page G', uv: 3490, pv: 4300, amt: 2100},
    {name: 'Page C', uv: 2000, pv: 6800, amt: 2290},
    {name: 'Page D', uv: 4780, pv: 7908, amt: 2000},
    {name: 'Page E', uv: 2890, pv: 9800, amt: 2181},
    {name: 'Page F', uv: 1390, pv: 3800, amt: 1500},
    {name: 'Page G', uv: 3490, pv: 4300, amt: 2100},
];

export const pdfConstants = {
  titleBorder: [false, true, false, true],
  standardBorder: [false, false, false, false],
  topBorder: [false, true, false, false],
  bottomBorder: [false, false, false, true],
  noBorder: [false, false, false, false],
};

export const months = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sept',
  'Oct',
  'Nov',
  'Dec',
];

export const PASSWORD_MINIMUM_LENGTH = 6;
export const STREAM_STATUSES = [ 'Scheduled', 'Ongoing', 'Archived' ];
